/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import Form from '../components/form';
import OGImage from '../images/tom-hirst-in-oslo.jpg';

const UsesPage = ( props ) => {
    const {
        location,
    } = props;

    const SeoImageUrl = `https://www.tomhirst.com${OGImage}`;

    return (
        <Layout location={ location }>
            <SEO
                title="Tom Hirst's Tools"
                description="The hardware and software I use for business, programming, writing, podcasting and creating."
                imageAlt="About Tom Hirst"
                imageUrl={ SeoImageUrl }
            />
            <Section narrow>
                <h1>My Tools</h1>
                <p className="lead">
                    The hardware and software I use for business, programming, writing, podcasting and creating.
                </p>
                <p>You can learn more about my story and how I use these tools to do my work on my about page.</p>
                <h2>Hardware</h2>
                I&apos;m an investor in equipment that allows me to do my job better and will last me a long time.
                <h3>Office Setup</h3>
                Having worked from home for 12 years, my office is my sanctuary. Here&apos;s my current setup:
                - Desk
                - Chair
                - Computer
                - Monitors
                - Keyboard
                - Mouse
                - Headphones
                - Camera
                - Phone
                - Tablet

                <h2>Software</h2>
                Everything I do is digital.
                <h2 id="hire-me">I&apos;m available for hire</h2>
                <p>Fill out the form below to start a conversation with me:</p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, I have a project to talk with you about."
                    pathname={ location && location.pathname }
                />
            </Section>
        </Layout>
    );
};

UsesPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

UsesPage.defaultProps = {
    location: {},
};

export default UsesPage;
